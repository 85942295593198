.Northern {
  fill: red;
  color: red;
}

.Eastern {
  fill: brown;
  color: brown;
}

.Middle {
  fill: orange;
  color: orange;
}

.Southern {
  fill: purple;
  color: purple;
}

.Western {
  fill: darkgreen;
  color: darkgreen;
}

