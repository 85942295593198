.App {
  text-align: center;
}

*, ::before, ::after {
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
}


