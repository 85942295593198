.home__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.howto__container {
  margin: 1rem 0 4rem 0;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 1rem;
  }

  p {
    margin: 1rem;
  }

  ul {
    list-style: none;
  }
}

.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    border-radius: 50%;
    margin: 2rem;
  }

  p {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .home__container {
    width: 100%;
  }

  .howto__container {
    width: 80%;
    margin: 1rem;
  }
}
