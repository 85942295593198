.visited__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: 100vh;
  color: #242424;

  h1 {
    margin-top: 1rem;
    font-size: 2rem;
  }
}

.visited__container--ulist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 1rem;
  width: 95%;
  padding: 1rem;

  h2 {
    font-size: 1.25rem;
  }
}

.visited__container--indivlist {
  margin: 1rem auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #999;
  list-style: none;
  height: 40vh;
  width: 300px;
  padding: 1rem;
  box-shadow: 8px 8px 8px 0 rgba(0,0,0,0.5);
  overflow-y: scroll;

  li {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .visited__container {
    h1 {
      font-size: 1.5rem;
      text-align: center;
    }
  }
}
