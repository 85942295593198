.Asia {
  fill: red;
  color: red;
}

.Africa {
  fill: brown;
  color: brown;
}

.Europe {
  fill: orange;
  color: orange;
}

.Australia\/Oceania {
  fill: purple;
  color: purple;
}

.South.America {
  fill: darkgreen;
  color: darkgreen;
}

.North.America {
  fill: blue;
  color: blue;
}

.Artarctic {
  fill: lightgrey;
  color: lightgrey;
}

.legend {
  position: absolute;
  padding: 1rem;
  top: 1rem;
  right: 1rem;
  width: 200px;
  height: fit-content;
  background-color: #fff;
  box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 1rem;

  h4 {
    margin-bottom: 1rem;
  }
}
