.Northeast {
  fill: red;
  color: red;
}

.Southwest {
  fill: brown;
  color: brown;
}

.West {
  fill: orange;
  color: orange;
}

.Southeast {
  fill: purple;
  color: purple;
}

.Midwest {
  fill: darkgreen;
  color: darkgreen;
}
