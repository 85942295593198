.Ilocos {
  fill: red;
  color: red;
}

.Cagayan.Valley {
  fill: brown;
  color: brown;
}

.Central.Luzon {
  fill: orange;
  color: orange;
}

.Calabarzon {
  fill: purple;
  color: purple;
}

.Bicol {
  fill: darkgreen;
  color: darkgreen;
}

.Western.Visayas {
  fill: blue;
  color: blue;
}

.Central.Visayas {
  fill: lightgrey;
  color: lightgrey;
}

.Eastern.Visayas {
  fill: #bfacaa;
  color: #bfacaa;
}

.Zamboanga.Peninsula {
  fill: #02020a;
  color: #02020a;
}

.Northern.Mindanao {
  fill: #05204a;
  color: #05204a;
}

.Davao {
  fill: #b497d6;
  color: #b497d6;
}

.Soccsksargen {
  fill: #e0479e;
  color: #e0479e;
}

.Caraga {
  fill: #e7e247;
  color: #e7e247;
}

.National.Capital {
  fill: #3d3b30;
  color: #3d3b30;
}

.Cordillera {
  fill: #4d5061;
  color: #4d5061;
}

.Bangsamoro {
  fill: #5c80bc;
  color: #5c80bc;
}

.Mimaropa {
  fill: #a8c686;
  color: #a8c686;
}
